import * as AUTH_CONSTANT from './auth'
import * as COGNITO_CONSTANT from './cognito'
import * as HOME_CONSTANT from './home'
import * as LAYOUT_CONSTANT from './layout'
import * as ROUTE_CONSTANT from './route'
import * as GLOBAL_CONSTANT from './global'
import { tokens } from '@JOTAJornalismo/jota-design-system'

const HEADER_CONSTANT = {
    search: {
        input: 'Buscar no JOTA'
    },
    icons: {
        colors: tokens.colors.gray.jotaGrayDark
    },
    btnAssine: {
        text: 'Assine',
        link: 'https://jota.info/assine'
    },
    btnLogin: {
        text: 'Entrar'
    },
    userMenu: {
        minhaConta: 'Minha Conta'
    }
}

export {
    AUTH_CONSTANT,
    COGNITO_CONSTANT,
    HOME_CONSTANT,
    LAYOUT_CONSTANT,
    ROUTE_CONSTANT,
    HEADER_CONSTANT,
    GLOBAL_CONSTANT
}
