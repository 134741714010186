import {
    UserInterface,
    UserType,
    tokenType
} from '../interfaces/User.interface'
import { CognitoUser } from '@aws-amplify/auth'

type CognitoTokens = {
    id: string | undefined
    refresh: string | undefined
    access: string | undefined
}

type UserAttributesType = {
    Name: string
    Value: string
}

type UserAttributesData =
    | {
          UserAttributes: UserAttributesType[]
          Username: string
      }
    | undefined

/**
 * User Class
 * The User class *
 */
export class User implements UserInterface {
    private static _instance: User
    private name = ''
    private email = ''
    private familyName = ''
    private givenName = ''
    private paywallAccount = 0
    private permissions = ''
    private cognitoUsername = ''
    private tokens: CognitoTokens = {
        id: undefined,
        refresh: undefined,
        access: undefined
    }
    private userCognito: CognitoUser | null = null
    private userBO?: object

    /**
     * The constructor is private, so the class can't be instantiated.
     * @param {cognitoUser} userTemp
     * @param {object} userBO
     * @return {void}
     */
    constructor(userTemp: CognitoUser, userBO?: object | null) {
        this.userCognito = userTemp

        const userSession = userTemp?.getSignInUserSession()

        userTemp.getUserData((error: any, data: UserAttributesData) => {
            if (error) {
                return
            }

            this.email =
                data?.UserAttributes.filter(
                    (item: UserAttributesType) => item.Name === 'email' && item
                )[0].Value ?? ''

            this.name =
                data?.UserAttributes.filter(
                    (item: UserAttributesType) => item.Name === 'name' && item
                )[0].Value ?? ''

            this.givenName =
                data?.UserAttributes.filter(
                    (item: UserAttributesType) =>
                        item.Name === 'given_name' && item
                )[0].Value ?? ''

            this.familyName =
                data?.UserAttributes.filter(
                    (item: UserAttributesType) =>
                        item.Name === 'family_name' && item
                )[0].Value ?? ''

            this.paywallAccount = parseInt(
                data?.UserAttributes.filter(
                    (item: UserAttributesType) =>
                        item.Name === 'custom:paywall_account' && item
                )[0].Value ?? '0'
            )
        })
        this.tokens.id = userSession?.getIdToken().getJwtToken()
        this.tokens.access = userSession?.getAccessToken().getJwtToken()
        this.tokens.refresh = userSession?.getRefreshToken().getToken()

        if (userBO) {
            this.userBO = userBO
        }
    }

    /**
     * setToken
     * @param {any} token
     * @return {this}
     */
    public setToken(token: tokenType) {
        this.tokens.id = token.idToken.jwtToken
        this.tokens.access = token.accessToken.jwtToken
        this.tokens.refresh = token.refreshToken.token

        return this
    }

    /**
     * Get Access Token
     * @return {string}
     */
    public getIDToken() {
        return this.tokens.id
    }

    /**
     * Get UserBO
     * @return {object}
     */
    public getUserBO() {
        return this.userBO
    }

    /**
     *
     * @return {string}
     */
    public getUser() {
        return this
    }

    /**
     * This user is logged on info?
     * @return {boolean} A boolean value.
     */
    public isLogged(): boolean {
        return false
    }

    /**
     * Make login on Amplify Cognito
     * @return {boolean}
     */
    public makeLogin(): boolean {
        return false
    }

    /**
     * Return User Type
     * @return {UserType}
     */
    public getUserTyped(): UserType {
        return UserType.normal
    }

    /**
     * First Login from this user?
     * @return {boolean} false
     */
    public firstLogin(): boolean {
        return false
    }

    /**
     * Return User Email
     * @return {string}
     */
    public getEmail(): string {
        return this.email
    }

    /**
     * Return User Name
     * @return {string}
     */
    public getName(): string {
        return `${this.givenName} ${this.familyName}`
    }

    /**
     * Return first name
     * @return {string}
     */
    public getFirstName(): string {
        return this.givenName
    }

    /**
     * Return User paywall_account
     * @return {number}
     */
    public getPaywall(): number {
        return this.paywallAccount
    }

    /**
     * Return Cognito User
     * @return {CognitoUser | null | undefined}
     */
    public getUserCognito(): CognitoUser | null | undefined {
        return this.userCognito
    }
}
