import { combineReducers } from 'redux'
import { authReducer, uiReducer } from './../ducks'

const rootReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
