/**
 * HOME CONSTANTS
 * const variables with home values
 */
export const HOME = {
    JOTA_PRO_CTA: {
        logout: {
            desktop:
                'Tenha acesso ao melhor serviço de inteligência política e jurídica do Brasil.',
            mobile: 'Nao logado em mobile'
        },
        logged: {
            normal: 'Tenha acesso ao melhor serviço de inteligência política e jurídica do Brasil',
            mobile: 'logado em mobile'
        }
    },

    JOTA_PRO_CONHECA: 'Conheça o JOTA PRO.'
}

export const PATROCINADO = 'patrocinado'
export const ARTIGO = 'artigo'
export const COLUNA = 'coluna'
