import { UIState } from './../types/ui'
import { Auth } from 'aws-amplify'
import { awsconfig } from './../amplify'
import { AppDispatch } from './../types/auth'
import { AnyAction } from 'redux'

Auth.configure(awsconfig)

/**
 * Redux Duck Pattern
 * Authorization
 */
const initialState: UIState = {
    isCompact: false,
    sidebar: {
        isOpen: false
    },
    userMenu: {
        isOpen: false
    },
    search: {
        compactUI: {
            show: false
        }
    }
}

//
// Reducers - Redux Duck Pattern
//
const UIDuck = (
    state: UIState = initialState,
    action: AnyAction = { type: '' }
): UIState => {
    switch (action.type) {
        /**
         * SIDEBAR - toggle
         */
        case 'SIDEBAR_TOOGLE':
            return {
                ..._getCommonState(state),
                sidebar: {
                    ...state.sidebar,
                    isOpen: action.payload
                        ? action.payload
                        : !state.sidebar.isOpen
                }
            }

        /**
         * USER MENU - toggle
         */
        case 'USER_MENU_TOOGLE':
            return {
                ..._getCommonState(state),
                userMenu: {
                    ...state.userMenu,
                    isOpen: action.payload.type
                        ? action.payload.type
                        : !state.userMenu.isOpen
                }
            }

        /**
         * SEARCH Compact - toggle
         */
        case 'SEARCH_COMPACT_TOOGLE':
            return {
                ..._getCommonState(state),
                search: {
                    ...state.search,
                    compactUI: {
                        ...state.search.compactUI,
                        show:
                            action.payload.show !== undefined
                                ? action.payload.show
                                : !state.search.compactUI.show
                    }
                }
            }

        /**
         * HEADER TYPE - toogle
         */
        case 'HEADER_TYPE_TOOGLE':
            return {
                ..._getCommonState(state),
                isCompact:
                    action.payload.type !== undefined
                        ? action.payload.type
                        : !state.isCompact
            }

        default:
            return state
    }
}

export default UIDuck

const _getCommonState = (state: UIState) => ({
    ...state,
    sidebar: {
        isOpen: false
    },
    userMenu: {
        isOpen: false
    },
    search: {
        compactUI: {
            show: false
        }
    }
})

//
// Actions - Redux Duck Pattern
//
export type Actions = ReturnType<
    | typeof sidebarToogleAction
    | typeof userMenuToogleAction
    | typeof searchCompactToogleAction
    | typeof headerTypeToogleAction
>

/**
 * Sidebar Toogle Action
 * @param {boolean} type
 * @return {object}
 */
export const sidebarToogleAction = (type: boolean) => ({
    type: 'SIDEBAR_TOOGLE',
    payload: type
})

/**
 * User Menu Toogle Action
 * @param {boolean} type
 * @return {object}
 */
export const userMenuToogleAction = (type: boolean) => ({
    type: 'USER_MENU_TOOGLE',
    payload: type
})

/**
 * Search Compact Toogle Action
 * @param {boolean} show
 * @return {object}
 */
export const searchCompactToogleAction = (show: boolean) => ({
    type: 'SEARCH_COMPACT_TOOGLE',
    payload: show
})
/**
 * Header Type Toogle Action
 * @param {boolean} type
 * @return {object}
 */
export const headerTypeToogleAction = (type: boolean) => ({
    type: 'HEADER_TYPE_TOOGLE',
    payload: type
})

//
// Async Opetarions - Redux Duck Pattern
//

/**
 * SIDEBAR TOOGLE Event
 * @param {boolean} setOpen
 * @return {dispatchEvent}
 */
export const sidebarToogle = (setOpen = false) => {
    return async (dispatch: AppDispatch) => {
        dispatch(sidebarToogleAction(setOpen))
    }
}

/**
 * SIDEBAR TOOGLE Event
 * @param {boolean} setOpen
 * @return {dispatchEvent}
 */
export const userMenuToogle = (setOpen = false) => {
    return async (dispatch: AppDispatch) => {
        dispatch(userMenuToogleAction(setOpen))
    }
}

/**
 * SIDEBAR TOOGLE Event
 * @param {boolean} show
 * @return {dispatchEvent}
 */
export const searchCompactToogle = (show = false) => {
    return async (dispatch: AppDispatch) => {
        dispatch(searchCompactToogleAction(show))
    }
}

/**
 * HEADER TOOGLE Event
 * @param {boolean} type
 * @return {dispatchEvent}
 */
export const headerTypeToogle = (type = false) => {
    return async (dispatch: AppDispatch) => {
        dispatch(headerTypeToogleAction(type))
    }
}
