export const ItemsBulletLP = {
    Tributos: [
        'Bastidores de temas tributários em Carf, STF, STJ e Congresso',
        'Pesquisa de jurisprudência em conteúdos JOTA na plataforma',
        'Antecipação das mudanças no contencioso tributário nacional'
    ],
    Poder: [
        'Bastidores do que de mais importante acontece nos Três Poderes',
        'Análise política com melhores especialistas do mercado',
        'Alertas, relatórios, calls e muito mais na palma da sua mão'
    ],
    Saúde: [
        'Bastidores do sistema regulatório da saúde com informações exclusivas',
        'Acompanhamento de projetos de lei no Congresso e em Assembleias Legislativas',
        'Alertas, relatórios, calls e muito mais na palma da sua mão'
    ]
}

export const FormRD = {
    Poder: 'marketing-formulario-conversao-pro-poder-jota-unico-c685ab9b664fc91623f7',
    Tributos:
        'marketing-formulario-conversao-pro-tributos-jota-unico-90817bd87d6fbef6dd4f',
    Saúde: '05-10-2024-rdstation-saude-modal-conversao-mql-formulario-marketing-d273e85a461e32ce0d72'
}

export const RD_ID = 'UA-53687336-1'
export const INFO_PRO_URL =
    process.env.NEXT_PUBLIC_LP_GERAL_PRO ??
    'https://www.jota.info/newsletter-jota?non-beta=1'

export const MATINAL_ID = process.env.NEXT_PUBLIC_PRO_NEWSLETTER_MATINAL_ID
export const VERTICAL = {
    PODER: 'Poder',
    TRIBUTOS: 'Tributos',
    SAUDE: 'Saúde'
}

export type TYPE_OF_VERTICAL = 'Poder' | 'Tributos' | 'Saúde'

export const ALERTAS = 'alertas'
export const NEWSLETTER = 'newsletter'

export const PRO = {
    PODER: 'Poder',
    TRIBUTOS: 'Tributos',
    SAUDE: 'Saúde',
    ENERGIA: 'Energia',
    ALERTAS: 'Alertas'
}
