/* eslint-disable no-unused-vars */
export interface UserInterface {
    makeLogin(): boolean
    isLogged(): boolean
    getUserTyped(): UserType
    firstLogin(): boolean
}

export enum UserType {
    normal = 'Normal',
    estudante = 'Estudante',
    pro = 'Pro',
    gratuito = 'Gratuíto'
}

export type UserLogin = {
    email: HTMLInputElement | null
    password: HTMLInputElement | null
}

export type tokenType = {
    idToken: {
        jwtToken: string
    }
    accessToken: {
        jwtToken: string
    }
    refreshToken: {
        token: string
    }
}
