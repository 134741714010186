/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose,
    Store
} from 'redux'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import { createWrapper } from 'next-redux-wrapper'
import { AuthState } from '../types/auth'
import { UIState } from '../types/ui'

//  mount it on the Store
let store: any = null

// DEVTOOLS PLUGIN - Check
if (
    (typeof window !== 'undefined' && (window as any))
        .__REDUX_DEVTOOLS_EXTENSION__
) {
    store = createStore(
        rootReducer,
        compose(
            applyMiddleware(thunk),
            (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
                (window as any).__REDUX_DEVTOOLS_EXTENSION__({
                    trace: true,
                    traceLimit: 25
                })
        )
    )
} else {
    store = createStore(rootReducer, applyMiddleware(thunk))
}

export const makeStore = () => store
export const wrapper = createWrapper<
    Store<{
        auth: AuthState
        ui: UIState
    }>
>(makeStore, { debug: false })
