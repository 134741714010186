/**
 * JOTA INFO Sections
 */
const section = {
    news: 'noticias',
    category: 'categorias',
    search: 'pesquisar',
    tag: 'tags'
}

/**
 * URLS Constants
 */
export const URLS = {
    autentication: {
        login: '/login',
        logout: '/logout',
        forgot: '/esqueci-a-senha',
        reset: '/reiniciar-senha',
        profile: '/minha-conta'
    },
    home: '/',
    root: '/home',
    info: {
        list: `/${section.news}/:news`,
        search: `/${section.search}/:term`,
        category: `/${section.category}/:category`,
        read: `/${section.news}/:id`,
        tag: `/${section.tag}/:tag`
    },

    pro: '/pro',

    noticias: {
        info: `/noticia`,
        especiais: '/especiais'
    },

    tag: '/tudo-sobre'
}

export const ROUTE = {
    HOME_LOGGED: '/',
    LOGIN: '/login',
    LOGOUT: '/logout'
}

export const MENU = {
    forgot: '/forgot-password',
    reset: '/reset-password',
    login: '/login'
}

/**
 * Check if new is special or info
 * @param {Array<any>} categories
 * @param {string} slug
 * @param {string} wpLink
 * @param {string} osLink
 * @return {string}
 */
export function checkSpecialOrInfoNews(
    categories: any,
    slug?: string | null,
    wpLink?: string | null,
    osLink?: string
) {
    for (const category of categories) {
        if (category.slug === 'especiais') {
            return `${URLS.noticias.especiais}/${slug}`
        }
    }
    if (osLink) {
        const redirectLink = osLink.split('.info')[1]
        return redirectLink
    } else {
        return wpLink
    }
}
