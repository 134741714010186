import { isWindowLoaded } from './geral'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA4

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
    if (isWindowLoaded()) {
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url
        })
    }
}

type EventGA4 = {
    action: string
    category: string
    label: string
    value: string
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const makeGA4Event = (props: EventGA4) => {
    if (isWindowLoaded()) {
        window.gtag('event', props.action, {
            event_category: props.category,
            event_label: props.label,
            value: props.value
        })
    }
}
