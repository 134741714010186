import { tokens } from '@JOTAJornalismo/jota-design-system'

export const { spaces, colors } = tokens

export type periodoInterface = {
    label: string
    value: string
    isActive?: boolean
}

export const periodos: periodoInterface[] = [
    {
        label: 'Todos',
        value: 'todos',
        isActive: true
    },
    {
        label: 'Hoje',
        value: 'hoje'
    },
    {
        label: 'Ontem',
        value: 'ontem'
    },
    {
        label: 'Últimos 7 dias',
        value: 'ultimos7dias'
    },
    {
        label: 'Últimos 30 dias',
        value: 'ultimos30dias'
    }
    // {
    //     label: 'Este mês',
    //     value: 'esteMes'
    // },
    // {
    //     label: 'Mês Passado',
    //     value: 'mesPassado'
    // }
]

export const getPeriodoByString = (periodo: string) => {
    return periodos.filter((item) => item.value == periodo && periodo)
}

export const API_FAKE1 =
    'https://mocki.io/v1/4c8fef1c-ab76-4b4c-8dcc-b6d711e4eece'
export const API_FAKE2 =
    'https://mocki.io/v1/52492ab9-db65-4975-9886-1ecc7d2ecc67'
export const HOME_BREAD = 'Início'

export const ORDER_ITEMS = [
    {
        id: 'more-recent',
        value: 'Mais recentes'
    },
    {
        id: 'older',
        value: 'Mais antigos'
    }
]

export const getOrderObject = (order: string) => {
    if (order == 'more-recent') {
        return ORDER_ITEMS[0]
    } else if (order == 'older') {
        return ORDER_ITEMS[1]
    }
}
