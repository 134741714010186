import { Auth } from 'aws-amplify'
import { awsconfig } from './../amplify'
import { AppDispatch, AuthState } from './../types/auth'
import { AnyAction } from 'redux'
import { CognitoUser } from '@aws-amplify/auth'

import { axiosJOTA, ProfileAPI } from '../api'
import { ErrorAuthType } from '../../libs/interfaces/Authentication.interface'
import { tokenType } from '../../libs/interfaces/User.interface'
import { User } from '../../libs/model/user'
import { JU_TOKEN } from '@App/libs/utils/constants/global'

Auth.configure(awsconfig)

/**
 * Redux Duck Pattern
 * Authorization
 */
const initialState: AuthState = {
    authState: 'logout',
    user: null,
    error: null,
    loading: false,
    userBO: {},
    isProUser: false
}

//
// Reducers - Redux Duck Pattern
//
const AuthDuck = (
    state: AuthState = initialState,
    action: AnyAction = { type: '' }
): AuthState => {
    switch (action.type) {
        /**
         * Auth System Error
         * Description: Error on Cognito Authentication
         */
        case 'AUTH_SYSTEM_ERROR':
            return {
                ..._getCommonState(state),
                error: `AUTH_SYSTEM_ERROR ${action.payload}`,
                loading: false
            }

        /**
         * Auth Begin Loading
         * Description: Starts process of authentication on Cognito set loading is true
         */
        case 'AUTH_BEGIN_LOADING':
            return {
                ..._getCommonState(state),
                loading: true
            }

        /**
         * Auth Init
         * Description: Start auth with any error
         */
        case 'AUTH_INIT':
            return {
                ...initialState,
                error: action.payload,
                authState: action.payload
            }

        /**
         * Auth Change Auth State
         * Description: Detect any change of user or auth from Cognito
         */
        case 'AUTH_CHANGE_AUTH_STATE':
            return {
                ..._getCommonState(state),
                authState: action.payload
            }

        /**
         * Auth Sign In Success
         * Description: Auth Success - receive id token
         */
        case 'AUTH_SIGN_IN_SUCCESS':
            return _getUserSuccess(state, action)

        /**
         * Auth Forgot Password Success
         */
        case 'AUTH_FORGOT_PASSWORD_SUCCESS':
            return {
                ..._getCommonState(state),
                authState: 'forgotPasswordReset'
            }

        /**
         * Auth Forgot Password Error
         */
        case 'AUTH_FORGOT_PASSWORD_ERROR':
            return {
                ..._getCommonState(state),
                error: `AUTH_FORGOT_PASSWORD_ERROR ${action.payload}`,
                loading: false,
                authState: 'forgotPasswordError'
            }

        /**
         * Auth Fetch authed User
         * Description: Get user authenticated on Cognito
         */
        case 'AUTH_FETCH_AUTHED_USER':
            return _getUserSuccess(state, action)

        case 'AUTH_REFRESH_TOKEN':
            const isProUser =
                action.payload.userBO.profile.has_tributos ||
                action.payload.userBO.profile.has_poder ||
                action.payload.userBO.profile.has_saude
            const idToken = action.payload.token.idToken.jwtToken
            const storageJOTAToken = {
                idToken,
                email: state.user?.getEmail()
            }
            localStorage.setItem(JU_TOKEN, JSON.stringify(storageJOTAToken))

            return {
                ..._getCommonState(state),
                user: new User(action.payload.user, action.payload.userBO)
                    .setToken(action.payload.token)
                    .getUser(),
                authState: 'user_logged',
                loading: false,
                isProUser
            }
        default:
            return state
    }
}

export default AuthDuck

const _getCommonState = (state: AuthState) => ({
    ...state,
    error: '',
    loading: false
})

const _getUserSuccess = (
    state: AuthState,
    action: AnyAction = { type: '' }
) => ({
    ..._getCommonState(state),
    user: new User(action.payload).getUser(),
    authState: 'user_logged',
    loading: false,
    error: ''
})

//
// Actions - Redux Duck Pattern
//
export type Actions = ReturnType<
    | typeof authForgotPasswordSuccess
    | typeof forgotPasswordChangeSuccess
    | typeof forgotPasswordChangeError
    | typeof authInit
    | typeof authBeginLoading
    | typeof authError
    | typeof changeAuthState
    | typeof authSignInSuccess
    | typeof authFirstLogin
    | typeof fetchAuthedUserSuccess
    | typeof updateUserBO
>

/**
 * Auth Forgot Password - Success
 * @param {string} email
 * @return {object} payload
 */
export const authForgotPasswordSuccess = (email: string) => ({
    type: 'AUTH_FORGOT_PASSWORD_SUCCESS',
    payload: email
})

/**
 * Forgot Passowrd Change - Success
 * @return {object}
 */
export const forgotPasswordChangeSuccess = () => ({
    type: 'AUTH_FORGOT_PASSWORD_SUCCESS',
    payload: 'verificar'
})

/**
 * Forgot Password Change Error
 * @param {any} err
 * @return {object}
 */
export const forgotPasswordChangeError = (err: string) => ({
    type: 'AUTH_FORGOT_PASSWORD_ERROR',
    payload: err
})

/**
 * Auth Init
 * @param {string} status
 * @return {object}
 */
export const authInit = (status: string) => ({
    type: 'AUTH_INIT',
    payload: status
})

/**
 * Auth Begin Loading
 * @return {object}
 */
export const authBeginLoading = () => ({
    type: 'AUTH_BEGIN_LOADING',
    payload: 'teste'
})

/**
 * Auth Error
 * @param {string} err
 * @return {object}
 */
export const authError = (err: string) => ({
    type: 'AUTH_SYSTEM_ERROR',
    payload: err
})

/**
 * Change Auth State
 * @param {string} value
 * @return {object}
 */
export const changeAuthState = (value: string) => ({
    type: 'AUTH_CHANGE_AUTH_STATE',
    payload: value
})

/**
 * Auth Sign In - Success
 * @param {CognitoUser} user
 * @return {object}
 */
export const authSignInSuccess = (user: CognitoUser) => {
    return {
        type: 'AUTH_SIGN_IN_SUCCESS',
        payload: user
    }
}

/**
 * Cognito - First Login
 * @param {string} email
 * @param {string} password
 * @return {void}
 */
export const authFirstLogin = () => ({
    type: 'AUTH_FIRST_LOGIN'
})

/**
 * Auth Forgot Password Success
 * @param {CognitoUser} user
 * @return {object} user
 */
export const fetchAuthedUserSuccess = (user: CognitoUser) => ({
    type: 'AUTH_FETCH_AUTHED_USER',
    payload: user
})

export const refreshTokens = (
    refreshToken: tokenType,
    user: CognitoUser,
    userBO: object
) => ({
    type: 'AUTH_REFRESH_TOKEN',
    payload: {
        token: refreshToken,
        user: user,
        userBO: userBO
    }
})

/**
 * Update User from Backoffice
 * @param {any} user
 * @return {object}
 */
export const updateUserBO = (user: any) => ({
    type: 'USER_BO_UPDATE',
    payload: user
})

//
// Async Opetarions - Redux Duck Pattern
//

/**
 * Sign Out - Logout
 * @return {void}
 */
export const signOut = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(authInit('logout'))
        try {
            await Auth.signOut()
        } catch (err: unknown) {
            const typedError = err as ErrorAuthType
            dispatch(authError(typedError.message))
        }
    }
}

/**
 * Sign In - Login
 * @param {string} email
 * @param {string} password
 * @param {string} newPassword - First Login
 * @param {boolean} preserve - preserve login?
 * @return {void}
 */
export const signIn = (
    email: string,
    password: string,
    newPassword?: string | null | undefined
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading())
        try {
            const user = await Auth.signIn(email, password)

            // First Login - Flag Cognito
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                // has new password will change
                if (newPassword) {
                    const userChange = await Auth.completeNewPassword(
                        user,
                        newPassword
                    )
                    dispatch(authSignInSuccess(userChange))
                } else {
                    dispatch(authFirstLogin())
                }
            }

            dispatch(authSignInSuccess(user))
        } catch (err: unknown) {
            const typeError = err as ErrorAuthType
            dispatch(authError(typeError.message))
        }
    }
}

/**
 * Forgot Password
 * @param {string} email
 * @return {void}
 */
export const forgotPassword = (email: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading())
        Auth.forgotPassword(email, { context: 'ju' })
            .then(() => dispatch(authForgotPasswordSuccess(email)))
            .catch((err) => dispatch(authError(err)))
    }
}

/**
 * Fetch Autheticated User
 * @return {void}
 */
export const fetchAuthedUser = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading())
        try {
            const user: CognitoUser = await Auth.currentAuthenticatedUser()
            void Auth.currentSession().then((data) => {
                const dataToken: tokenType = {
                    idToken: {
                        jwtToken: data.getIdToken().getJwtToken()
                    },
                    accessToken: {
                        jwtToken: data.getAccessToken().getJwtToken()
                    },
                    refreshToken: {
                        token: data.getRefreshToken().getToken()
                    }
                }

                if (!dataToken.idToken.jwtToken) {
                    return
                }

                // Backoffice User data
                void axiosJOTA
                    .get(ProfileAPI.me(), {
                        headers: {
                            Authorization: `Bearer ${dataToken.idToken.jwtToken}`
                        }
                    })
                    .then((userBO) => {
                        dispatch(refreshTokens(dataToken, user, userBO.data))
                    })
            })
        } catch (error: unknown) {
            dispatch(authInit('NOT_LOGGED'))
        }
    }
}

/**
 * Forgot Password Submit
 * Description: In the forgot password email, the user received a code that it's necessary to be validated
 * @param {string} email
 * @param {string} code
 * @param {string} password
 * @return {void}
 */
export const forgotPasswordSubmit = (
    email: string,
    code: string,
    password: string
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(authBeginLoading())
        try {
            await Auth.forgotPasswordSubmit(email, code, password)
            dispatch(forgotPasswordChangeSuccess())
        } catch (err: any) {
            dispatch(forgotPasswordChangeError(err.log))
        }
    }
}
