/**
 * COGNITO CONSTANTS
 * const variables with cognito logic, warnings and errors
 */

// Cognito Warnings
export const COGNITO_WARNINGS = {
    PASS_EXCEEDED: 'Password attempts exceeded',
    INCORRECT: 'Incorrect username or password.',
    PRE_TOKEN: 'PreTokenGeneration failed with error Unexpected error.',
    NOT_LOGGED: 'NOT_LOGGED',
    LIMIT_EXCEEDED: 'LimitExceededException',
    EXPIRED_CODE: 'ExpiredCodeException',
    FORGOT_PASSWORD_RESET: 'forgotPasswordReset',
    USERNAME_EMPTY: 'Username cannot be empty'
}

// Cognito Responses
export const COGNITO_RESPONSES = {
    PASS_EXCEEDED:
        'Tentativas de senha excedidas. Por favor aguarde alguns minutos',
    INCORRECT: 'E-mail ou senha incorretos. Tente novamente.',
    PRE_TOKEN:
        'Houve um erro, entre em contato com nossa equipe pelo email contato@jota.info e informe o código BKOFF',
    NOT_LOGGED: 'Usuário não autenticado. Por favor efetue o login.',
    LIMIT_EXCEEDED:
        'Limite de tentativas excedido, tente depois de algum tempo.',
    EXPIRED_CODE: 'Código inválido fornecido, solicite um código novamente.',
    PASSWORD_CHANGE:
        'Sua senha foi alterada com sucesso. Clique no botão abaixo para realizar o login.',
    ROLE_ERROR:
        'Você ainda não possui uma conta JOTA PRO. Solicite uma demonstração para sua empresa ou volte para o nosso site.',
    WRONG_PASS: 'Senha inválida',
    INPUT_ACTUAL_PASS_WRONG: 'A senha informada está incorreta',
    INPUT_ACTUAL_PASS_EXCEEDED:
        'Número de tentativas excedidas. Por favor aguarde alguns minutos',
    INPUT_PASS_NOT_EQUAL: 'As senhas não coincidem',
    INPUT_NOT_EMPTY: 'O campo não pode ficar vazio',
    PASS_SUCCESS: 'Sua senha foi alterada com sucesso.',
    USERNAME_EMPTY: 'Ocorreu um erro, tente novamente. COD: USER_EMPTY',
    PASS_MINIMUM: 'Digite pelo menos 8 caracteres'
}

export const ID_TOKEN = 'idToken'
