export const BREAKPOINTS = {
    SM: 499,
    MD: 1200,
    LG: 1440,
    SM_PX: () => `${BREAKPOINTS.SM}px`,
    LG_PX: () => `${BREAKPOINTS.LG}px`,
    MD_PX: () => `${BREAKPOINTS.MD}px`,
    pxSM: () => `${BREAKPOINTS.SM}px`,
    pxLG: () => `${BREAKPOINTS.LG}px`,
    pxMD: () => `${BREAKPOINTS.MD}px`
}

export const CONTAINER_WIDTH = `${BREAKPOINTS.SM}px`
export const CONTAINER_MAX_WIDTH = `${BREAKPOINTS.MD}px`
