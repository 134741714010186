/**
 * AUTH_CONSTANTS
 * const variables with auth logic
 */

export const AUTH_STATE = {
    LOGOUT: 'logout',
    USER_LOGGED: 'user_logged',
    RESET_PASSWORD_SUCCESS: 'forgotPasswordReset',
    RESET_PASSWORD_WRONG: 'forgotPasswordError',
    USER_NOT_LOGGED: 'NOT_LOGGED',
    EMAIL_VERIFY: 'Verifique seu e-mail',
    IS_PRO: 'is_pro'
}

export const LOGIN_FIELDS_MESSAGE = {
    emailWrong: 'Digite um e-mail válido',
    emailPlaceholder: 'Digite seu e-mail'
}

export const NORMAL = 'normal'
export const ERROR = 'error'
export const PASS_MINIMUM = 8

export const BACK_TO_LOGIN = 'voltar para o login'
export const PASSWORD_CHANGE =
    'Sua senha foi alterada com sucesso. Clique no botão abaixo para realizar o login'
export const PASSWORD_CHANGE_ERROR =
    'O tempo limite para a redefinição de senha expirou. Por favor, tente novamente.'
export const JU_LOGINREDIRECT = 'JU_loginredirect'
export const NOTICIA_ROUTE = '/noticia/'
export const PRO_ROUTE = '/pro/'
export const INSIDE_JU = 'cameFromJu'
